import { AppEnvironment } from "src/types/configTypes";

export type AppConfig = {
  env: string;
};

const creatAppConfigService = () => {
  const getAppEnvironmentData = async (): Promise<AppEnvironment> => {
    const config = await fetch("/config/config.json");
    const appConfig: AppConfig = await config.json();
    const env = appConfig.env;
    const envConfigPath = `/config/${env}Config.json`;
    const envConfig = await fetch(envConfigPath);
    const envConfigJSON: AppEnvironment = await envConfig.json();
    return envConfigJSON;
  };

  return {
    getAppEnvironmentData,
  };
};

export const appConfigService = creatAppConfigService();

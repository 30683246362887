import React from "react";
import ReactDOM from "react-dom";
import "src/index.css";
import { App } from "src/App";
import reportWebVitals from "src/reportWebVitals";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { createAxiosInstance } from "src/core/services/axiosService";
import { appConfigService } from "src/core/services/appConfigService";

initializeIcons();

(async () => {
  const envData = await appConfigService.getAppEnvironmentData();
  await createAxiosInstance(envData.apiBaseUrl);

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Checkbox, Stack } from "@fluentui/react";
import React from "react";
import { SurveyAnswer, SurveyQuestion } from "src/types/surveyTypes";

type MultipleChoiceProps = {
  question: SurveyQuestion;
  onChoice: (question: SurveyQuestion, answerText?: string, answerId?: string) => void;
};

type AnswerCheckBoxProps = {
  answer: SurveyAnswer;
  onChange: (answer: SurveyAnswer, checked?: boolean) => void;
};

const AnswerCheckBox = (props: AnswerCheckBoxProps) => {
  const { answer, onChange } = props;

  const handleChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    onChange(answer, checked);
  };

  return <Checkbox key={answer.id} label={answer.text} onChange={handleChange} styles={{ text: { fontSize: "14px" } }} />;
};

export const MultipleChoiceComponent = (props: MultipleChoiceProps) => {
  const { question, onChoice } = props;
  const { answers } = question;

  const onChange = (choice: SurveyAnswer, checked?: boolean) => {
    onChoice(question, choice.text, choice.id);
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {answers.map((a) => (
        <AnswerCheckBox key={a.id} answer={a} onChange={onChange} />
      ))}
    </Stack>
  );
};

import styled from "@emotion/styled";
import { SurveyQuestion } from "src/types/surveyTypes";
import { AnswerComponent } from "src/components/AnswerComponent";
import { Stack, Text } from "@fluentui/react";

type QuestionProps = {
  displaySequenceOrder: number;
  question: SurveyQuestion;
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: auto auto;
`;

const QuestionRoot = styled(Stack)`
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 2;
  grid-column-end: 3;
  margin-bottom: 16px;
`;

const QuestionText = styled(Text)`
  font-size: 17px;
  color: #333333;
`;

const RequiredField = styled.div`
  color: #ff0000;
  display: inline;
  margin-left: 4px;
`;

const AnswerContainer = styled.div`
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 3;
`;

export const QuestionComponent = (props: QuestionProps) => {
  const { displaySequenceOrder, question } = props;
  const { isRequired, text, subText } = question;

  return (
    <Root>
      <div>{displaySequenceOrder}.</div>
      <QuestionRoot tokens={{ childrenGap: 8 }}>
        <QuestionText variant={"mediumPlus"}>
          {text}
          {isRequired && <RequiredField>*</RequiredField>}
        </QuestionText>
        {!!subText && (
          <Text variant={"medium"} style={{ fontStyle: "italic" }}>
            {subText}
          </Text>
        )}
      </QuestionRoot>
      <AnswerContainer>
        <AnswerComponent question={question} />
      </AnswerContainer>
    </Root>
  );
};

import { useEffect } from "react";
import { LoadCss } from "./helper";
import { getUHF } from "src/services/uhfService";
import { mergeStyleSets } from "@fluentui/react";

const style=mergeStyleSets({footer:{bottom:"0px",marginTop:"0px"}})

const MsFooter = (props: any) => {
  
  useEffect(() => {
    
    async function parseXmlData() {
        
        const  xmlData:any  = await getUHF();
        if(xmlData){
            const tempHtml =
        xmlData.getElementsByTagName("footerHtml")[0].childNodes[0];
      const tempCss =
        xmlData.getElementsByTagName("cssIncludes")[0].childNodes[0];
      const tempJs =
        xmlData.getElementsByTagName("javascriptIncludes")[0].childNodes[0];

      const footerSection = document.getElementById("footer-container");
      footerSection && (await LoadCss(tempCss.wholeText, footerSection));

      const srcRegex = /<script src="([^"]*)"><\/script>/;
      const match = tempJs.wholeText.match(srcRegex);
      if (match) {
        const script = document.createElement("script");
        script.src = match[1];
        script.type = "text/javascript";
        if (footerSection) {
          footerSection.appendChild(script);
        }
      }
      
      const footer = document.getElementById("footer");
      if (footer) footer.innerHTML = tempHtml.wholeText;
        }
        
      
    }
    parseXmlData()
  }, []);

  return (
    <div id="footer-container" className={style.footer}>
      <div id="footer"></div>
    </div>
  );
};

export default MsFooter;
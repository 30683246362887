export function LoadCss(
    htmlString: string,
    parentContainer: HTMLElement
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      const div = document.createElement("div");
      div.innerHTML = htmlString.trim();
  
      const linkElements = Array.from(div.childNodes).filter(
        (node: ChildNode) => node.nodeName === "LINK"
      ) as HTMLLinkElement[];
  
      let linksCount: number = linkElements.length;
      linkElements.forEach((link: HTMLLinkElement) => {
        const loadComplete = () => {
          linksCount--;
          if (linksCount === 0) {
            resolve();
            link.onload = null;
            link.onerror = null;
          }
        };
        link.onload = loadComplete;
        link.onerror = loadComplete;
      });
  
      parentContainer.appendChild(div);
    });
  }
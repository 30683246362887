import axios, { AxiosInstance } from "axios";

let axiosInstance: AxiosInstance;

const createAxiosInstance = (apiBaseUrl: string) => {
  axiosInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
  });
};

export { createAxiosInstance, axiosInstance };

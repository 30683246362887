import _ from "lodash";
import moment from "moment";
import { SurveyQuestion, ValidationResult } from "src/types/surveyTypes";

const createValidationService = () => {
  const validateText = (question: SurveyQuestion, response?: string): ValidationResult => {
    let result = false;
    let message = "";

    const { validationType, numberValidationType, numberLimit, validationRegex, validationText } = question;

    const needsValidation = !_.isEmpty(validationType);
    if (needsValidation) {
      if (!response) {
        result = false;
        message = "Please enter a number";
      } else {
        if (validationType === "Number") {
          if (!!numberValidationType && numberValidationType.length > 0 && _.isNumber(numberLimit)) {
            switch (numberValidationType) {
              case "GreaterThan":
                result = !isNaN(+response) && +response > numberLimit;
                message = !result ? `Please enter a number greater than ${numberLimit}` : "";
                break;
              case "GreaterThanOrEqualTo":
                result = !isNaN(+response) && +response >= numberLimit;
                message = !result ? `Please enter a number greater tahn or equal to ${numberLimit}` : "";
                break;
              case "LessThan":
                result = !isNaN(+response) && +response < numberLimit;
                message = !result ? `Please enter a number less than ${numberLimit}` : "";
                break;
              case "LessThanOrEqualTo":
                result = !isNaN(+response) && +response <= numberLimit;
                message = !result ? `Please enter a number less than or equal to ${numberLimit}` : "";
                break;
            }
          } else {
            result = !isNaN(+response);
            message = !result ? "Please enter a number" : "";
          }
        } else if (validationType === "Email") {
          result = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(response);
          message = !result ? "Please enter a valid email" : "";
        } else if (
          validationType === "Custom" &&
          !!validationRegex &&
          validationRegex.length > 0 &&
          !!validationText &&
          validationText.length > 0
        ) {
          result = new RegExp(validationRegex).test(response);
          message = !result ? validationText : "";
        } else {
          result = true;
        }
      }
    } else {
      result = true;
    }

    return {
      valid: result,
      message: message,
    };
  };

  const validateDate = (question: SurveyQuestion, response?: string): ValidationResult => {
    let result = false;
    let message = "";

    const { validationType } = question;

    const needsValidation = !_.isEmpty(validationType);
    if (needsValidation) {
      if (!response) {
        result = false;
        message = "Please enter a number";
      } else if (validationType === "FutureDate") {
        const dt = moment(response);
        const now = moment();
        result = dt <= now;
        message = !result ? "Please enter a valid date. Future dates are not valid." : "";
      } else {
        result = true;
      }
    } else {
      result = true;
    }

    return {
      valid: result,
      message: message,
    };
  };

  return {
    validateText,
    validateDate,
  };
};

export const validationService = createValidationService();

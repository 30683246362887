import React, { useContext } from "react";
import { DataStore } from "src/store/dataStore";
import { DataStoreDispatch } from "src/store/dataStoreReducer";

type DataStoreContextType = {
  dataStore: DataStore;
  dataStoreDispatch: DataStoreDispatch;
};

export const DataStoreContext = React.createContext((null as unknown) as DataStoreContextType);

export const useDataStoreContext = () => useContext(DataStoreContext);

import { DatePicker, MessageBar, MessageBarType } from "@fluentui/react";
import React, { useState } from "react";
import { SurveyQuestion } from "src/types/surveyTypes";
import { validationService } from "src/services/validationService";

type DateProps = {
  question: SurveyQuestion;
  onChoice: (question: SurveyQuestion, answerText?: string) => void;
};

export const DateComponent = (props: DateProps) => {
  const { question, onChoice } = props;

  const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>("");

  const onChange = (date: Date | null | undefined) => {
    if (!!date) {
      const dt = date.toISOString();
      const validationResult = validationService.validateDate(question, dt);
      onChoice(question, dt);
      setShowValidationMessage(!validationResult.valid);
      setValidationMessage(validationResult.message);
    } else {
      onChoice(question);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <DatePicker onSelectDate={onChange} />
      {showValidationMessage && <MessageBar messageBarType={MessageBarType.error}>{validationMessage}</MessageBar>}
    </div>
  );
};

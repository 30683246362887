import { ApiResponse, apiService } from "src/services/apiService";
import { Survey, SurveyResponse, ValidationResponseDTO } from "src/types/surveyTypes";

const createSurveyService = () => {
  const validateSurvey = async (
    programType: string,
    surveyType: string,
    workshopType: string,
    requestId: string,
    skipValidationForRegistration?: boolean
  ): Promise<ApiResponse<ValidationResponseDTO>> => {
    const url = !!skipValidationForRegistration
      ? `/UserSurvey/ValidateSurvey/${programType}/${surveyType}/${workshopType}/${requestId}/${skipValidationForRegistration}`
      : `/UserSurvey/ValidateSurvey/${programType}/${surveyType}/${workshopType}/${requestId}`;
    const response = await apiService.get<ValidationResponseDTO>(url);
    return response;
  };

    const getSurvey = async (programType: string, surveyType: string, workshopType: string, requestId: string): Promise<ApiResponse<Survey>> => {
    const response = await apiService.get<Survey>(`/UserSurvey/GetSurvey/${programType}/${surveyType}/${workshopType}/${requestId}`);
    return response;
  };

  const submitSurvey = async (postData: SurveyResponse): Promise<ApiResponse<string>> => {
    const response = await apiService.post<string>("/UserSurvey/SubmitSurvey", postData);
    return response;
  };

  return {
    validateSurvey,
    getSurvey,
    submitSurvey,
  };
};

export const surveyService = createSurveyService();

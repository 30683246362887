import { SurveyQuestion, SurveyResponseItem } from "src/types/surveyTypes";
import { SingleChoiceComponent } from "src/components/SingleChoiceComponent";
import { MultipleChoiceComponent } from "src/components/MultipleChoiceComponent";
import { DateComponent } from "src/components/DateComponent";
import { RatingComponent } from "src/components/RatingComponent";
import { TextAnswerComponent } from "src/components/TextAnswerComponent";
import { LikertComponent } from "src/components/LikertComponent";
import { useDataStoreContext } from "src/store/dataStoreContext";

type AnswerProps = {
  question: SurveyQuestion;
};

export const AnswerComponent = (props: AnswerProps) => {
  const { question } = props;

  const { dataStore, dataStoreDispatch } = useDataStoreContext();
  const { requestId, survey, surveyResponse } = dataStore;

  const updateSurveyResponse = (question: SurveyQuestion, answerText?: string, answerId?: string, parentQuestionId?: string) => {
    const newItems = [...surveyResponse.userResponse];
    const match =
      question.type === "MultipleChoice"
        ? newItems.find((x) => x.questionId === question.id && x.answerId === answerId)
        : newItems.find((x) => x.questionId === question.id);

    if (!!match && !answerText) {
      // cancel response item
      const idx = newItems.indexOf(match);
      newItems.splice(idx, 1);
    } else if (!!match && !!answerText) {
      // update response item
      const idx = newItems.indexOf(match);
      const surveyResponseItem: SurveyResponseItem = {
        questionId: question.id,
        questionTypeId: question.questionTypeId,
        parentQuestionId: question.type === "LikertOption" ? parentQuestionId : undefined,
        answerId: answerId,
        answerText: answerText,
      };
      if (question.type === "MultipleChoice") {
        if (match.answerText !== answerText) {
          newItems.push(surveyResponseItem);
        } else {
          newItems.splice(idx, 1);
        }
      } else {
        newItems.splice(idx, 1, surveyResponseItem);
      }
    } else if (!!answerText) {
      // add response item
      const surveyResponseItem: SurveyResponseItem = {
        questionId: question.id,
        questionTypeId: question.questionTypeId,
        parentQuestionId: question.type === "LikertOption" ? parentQuestionId : undefined,
        answerId: answerId,
        answerText: answerText,
      };
      newItems.push(surveyResponseItem);
    }

    dataStoreDispatch({
      kind: "SET_SURVEY_RESPONSE",
      payload: {
        requestId: requestId,
        surveyId: survey.id,
        surveyTypeId: survey.typeId,
        workshopTypeId: survey.workshopTypeId,
        programTypeId: survey.programTypeId,
        userResponse: newItems,
      },
    });
  };

  switch (question.type) {
    case "SingleChoice":
      return <SingleChoiceComponent question={question} onChoice={updateSurveyResponse} />;
    case "MultipleChoice":
      return <MultipleChoiceComponent question={question} onChoice={updateSurveyResponse} />;
    case "Date":
      return <DateComponent question={question} onChoice={updateSurveyResponse} />;
    case "Rating":
      return <RatingComponent question={question} onChoice={updateSurveyResponse} />;
    case "Text":
    case "LargeText":
      return <TextAnswerComponent question={question} onChoice={updateSurveyResponse} />;
    case "Likert":
      return <LikertComponent question={question} onChoice={updateSurveyResponse} />;
    default:
      return <TextAnswerComponent question={question} onChoice={updateSurveyResponse} />;
  }
};

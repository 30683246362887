import { axiosInstance } from "src/core/services/axiosService";
import { trackError } from "src/telemetry/appInsights";

export type ApiResponse<T = any> = {
  data: T;
  hasError: boolean;
};

const createApiService = () => {
  const get = async <T = any>(endpoint: string): Promise<ApiResponse<T>> => {
    try {
      const response = await axiosInstance.get(endpoint);
      return {
        data: response.data,
        hasError: false,
      };
    } catch (error) {
      // Error 😨
      logError(error);
      return {
        data: {} as T,
        hasError: true,
      };
    }
  };

  const post = async <T = any>(endpoint: string, postData: any): Promise<ApiResponse<T>> => {
    try {
      const response = await axiosInstance.post(endpoint, postData);
      return {
        data: response.data,
        hasError: false,
      };
    } catch (error) {
      // Error 😨
      logError(error);
      return {
        data: {} as T,
        hasError: true,
      };
    }
  };

  const logError = (error: any) => {
    trackError(error, null);

    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log("Error", error.message);
    }
    console.log(error);
  };

  return {
    get,
    post,
  };
};

export const apiService = createApiService();

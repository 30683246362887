import { useEffect } from "react";
import { LoadCss } from "./helper";
import { getUHF } from "src/services/uhfService";

const MsHeader: React.FC = () => {
 

  useEffect(() => {
    async function parseXmlData() {
        const  xmlData:any  = await getUHF();
      const tempHtml =
        xmlData.getElementsByTagName("headerHtml")[0].childNodes[0];
      const tempCss =
        xmlData.getElementsByTagName("cssIncludes")[0].childNodes[0];

      const tempJs =
        xmlData.getElementsByTagName("javascriptIncludes")[0].childNodes[0];

      const headerSection = document.getElementById("header-container");

      headerSection && (await LoadCss(tempCss.wholeText, headerSection));

      const srcRegex = /<script src="([^"]*)"><\/script>/;
      const match = tempJs.wholeText.match(srcRegex);
      if (match) {
        const script = document.createElement("script");
        script.src = match[1];
        script.type = "text/javascript";
        if (headerSection) {
          headerSection.appendChild(script);
        }
      }
      const header = document.getElementById("header");
      if (header) header.innerHTML = tempHtml.wholeText;
    }
    parseXmlData();
  }, []);

  return (
    <div id="header-container" className="header">
      <div id="header"></div>
    </div>
  );
};

export default MsHeader;
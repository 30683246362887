import React from "react";
import { DataStore } from "src/store/dataStore";
import { AppEnvironment } from "src/types/configTypes";
import { Survey, SurveyResponse } from "src/types/surveyTypes";

type Action =
  | { kind: "SET_REQUEST_ID"; payload: string }
  | { kind: "SET_SURVEY_QUESTIONS"; payload: Survey }
  | { kind: "SET_SURVEY_RESPONSE"; payload: SurveyResponse }
  | { kind: "SET_APP_CONFIG"; payload: AppEnvironment };

export type DataStoreDispatch = React.Dispatch<Action>;

export const DataStoreReducer = (prevState: DataStore, action: Action): DataStore => {
  switch (action.kind) {
    case "SET_REQUEST_ID":
      return {
        ...prevState,
        requestId: action.payload,
      };
    case "SET_SURVEY_QUESTIONS":
      return {
        ...prevState,
        survey: action.payload,
      };
    case "SET_SURVEY_RESPONSE":
      return {
        ...prevState,
        surveyResponse: action.payload,
      };
    case "SET_APP_CONFIG":
      return {
        ...prevState,
        appConfig: action.payload,
      };
  }
};

import styled from "@emotion/styled";
import { FontIcon, Stack } from "@fluentui/react";
import { useLocation } from "react-router-dom";
import { Text } from "@fluentui/react";
import { useDataStoreContext } from "src/store/dataStoreContext";
import { getAppHeader } from "src/services/utils";

const Root = styled.div`
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 48px auto 48px;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
`;

const Header = styled.div`
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: #f8f8f8;
  height: 48px;
`;

const CenterColumn = styled.div`
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 2;
  display: flex;
  justify-content: center;
  align-content: start;
  background-color: #ffffff;
  padding-bottom: 8px;
  height:fit-content;
`;

const SurveyHeaderTextContainer = styled(Stack)`
  background-color: #0078d4;
  color: #ffffff;
  width: 100%;
`;

const SurveyHeaderText = styled(Text)`
  color: #ffffff;
`;

export const ThankyouPage = () => {
  const location = useLocation();
  const stateParam = location.state;
  const message = !!stateParam ? (stateParam as any).message : "Thank you for your submission!";

  const { dataStore } = useDataStoreContext();
  const { survey } = dataStore;

  const headerText = getAppHeader(survey.programKind);

  return (
    <Root>
      <Header />
      <CenterColumn>
        <Stack style={{ width: "100%" }} tokens={{ childrenGap: 48 }}>
          <SurveyHeaderTextContainer horizontalAlign="center" tokens={{ childrenGap: 16, padding: 8 }}>
            <SurveyHeaderText variant={"xxLarge"}>{headerText}</SurveyHeaderText>
          </SurveyHeaderTextContainer>
          <Text variant="xxLarge" style={{ alignSelf: "center" }}>
            {message}
          </Text>
          <FontIcon iconName="SkypeCircleCheck" style={{ alignSelf: "center", fontSize: 50, color: "#c2c2c2" }} />
        </Stack>
      </CenterColumn>
    </Root>
  );
};

export const getUHF = async () => {
    try {
      const result = await fetch(
        "https://uhf.microsoft.com/en-us/shell/xml/OfficeTransform?headerid=OfficeTransformHeader&footerid=OfficeTransformFooter"
      );
      const data = await result.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/xml");
      return doc;
    } catch (error: any) {
      console.log(error);
    }
  };
  
